import axios from 'axios';
import { Auth } from 'aws-amplify';
const url = axios.create({ baseURL: process.env.REACT_APP_API_PREFIX });

url.interceptors.request.use(async function (config) {
    try {
        const { signInUserSession: { accessToken: { jwtToken } } } = await Auth.currentAuthenticatedUser();
        config.headers = {
            authorization: jwtToken
        };
    } catch (error) {

    };
    return config;
}, function (error) {
    return Promise.reject(error);
});

url.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    return Promise.reject(error.response.data);
});

export default url;