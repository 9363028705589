import styles from './index.module.scss';
export default function EligibleView({ props }) {
    return (
        <>
            <div className={styles.info}>
                <div className={styles.left}>
                    <div className={styles.upper}>
                        {props?.userSaleDetails?.min
                            ? (
                                <>
                                    <img src="/assets/success.svg" alt="icon" className={styles.walletIcon} />
                                    You are whitelisted
                                </>
                            )
                            : (
                                <>
                                    <img src="/assets/close.svg" alt="icon" className={styles.walletIcon} />
                                    You are not whitelisted
                                </>
                            )
                        }
                    </div>
                </div>
                <div className={styles.right}>
                    <span>
                        {props?.userSaleDetails?.min || props?.userSaleDetails?.min
                            ? (props?.userSaleDetails?.max)
                            : (0)
                        }
                        <sub>ETH</sub>
                    </span>
                    <p>Your Max Cap</p>
                </div>
            </div>
            <button className={styles.connectedButton}>
                <img src="/assets/link.svg" alt="icon" className={styles.walletIcon} />
                Wallet Connected
            </button>
        </>
    )
}