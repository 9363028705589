import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import allActions from 'redux/actions';
import axios from '../../axios';

export default function BuyNowView({ props }) {

    const { RUSH_PRICE_USD, ETH_PRICE, wallerAddress } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [privateSaleContribute, setPrivateSaleContribute] = useState(0);
    const [contributeValue, setContributeValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getPrivateSaleContribute = async () => {
            try {
                let balance = await dispatch(allActions.buyActions.privateSaleContribute());
                setPrivateSaleContribute(Number(balance) / Math.pow(10, 18));
            } catch (error) {
                console.error(error);
            }
        }
        if (wallerAddress !== null) {
            getPrivateSaleContribute();
        }
    }, [wallerAddress, dispatch]);

    const buyToken = async () => {
        try {
            setIsLoading(true);
            let { data } = await axios.get('/rushsale/getSignatureToContribute');
            await dispatch(allActions.buyActions.contribute(data));
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const changeContributeValue = (value) => {
        setContributeValue(value);
    }

    return (
        <>
            <div className={styles.info}>
                <div className={styles.box}>
                    <span>Deposited</span>
                    <h1>{(privateSaleContribute)}</h1>
                </div>
                <div className={styles.box}>
                    <span>Max Cap</span>
                    <h1>{props?.userSaleDetails?.max}</h1>
                </div>
                <div className={styles.box}>
                    <span>Rush Tokens</span>
                    <h1>{privateSaleContribute / RUSH_PRICE_USD}</h1>
                </div>
            </div>

            <div className={styles.inputWraper}>
                <div className={styles.inputArea}>
                    <div className={styles.polygonIcon}>
                        <img src="/assets/eth.png" alt='icon' />
                    </div>
                    <input
                        type='number'
                        placeholder='Enter your Amount in ETH...'
                        className={styles.input}
                        value={contributeValue || ''}
                        onChange={(e) => changeContributeValue(e.target.value)}
                    />
                    <button className={styles.maxButton} onClick={() => setContributeValue(props?.userSaleDetails?.max - privateSaleContribute)}>
                        MAX
                    </button>
                </div>
                <div className={styles.infoArea}>
                    <div>{((contributeValue || 0 * ETH_PRICE) / RUSH_PRICE_USD).toFixed(2)} RUSH</div>
                    <div>{RUSH_PRICE_USD} USD/RUSH</div>
                </div>
            </div>

            <button className={styles.connectButton} disabled={isLoading || props?.saleConfiguration?.saleEnded || contributeValue > props?.userSaleDetails?.max || contributeValue === ''} onClick={() => buyToken()}>
                Buy Now
            </button>
        </>
    )
}