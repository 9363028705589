import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import BeforeSale from 'components/BeforeSale';
import DuringSale from 'components/DuringSale';
import { useDispatch, useSelector } from 'react-redux';
import allActions from 'redux/actions';
import axios from '../../axios';
import moment from 'moment';

export default function LandingRightPanel() {
    const dispatch = useDispatch();
    const [saleConfiguration, setSaleConfiguration] = useState({
        'saleStarted': false,
        'saleEnded': false,
    });
    const { walletAddress, web3Provider, connectedAccounts, connectedChainId } = useSelector(state => state.auth);
    const [userSaleDetails, setUserSaleDetails] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [collectedAmount, setCollectedAmount] = useState(0);
    // const [isWhitelisted, setWhitelisted] = useState({ nftStaked: false, tokenStaked: false });

    useEffect(() => {
        dispatch(allActions.authActions.getCacheProvider());
    }, [dispatch]);

    const connectWallet = async () => {
        dispatch(allActions.authActions.connectWallet());
    };

    useEffect(() => {
        web3Provider?.on('accountsChanged', (accounts) => {
            if (connectedAccounts[0] !== accounts[0]) {
                dispatch({ type: 'LOAD_CONNECTED_ACCOUNT', payload: accounts });
            }
        });

        web3Provider?.on('chainChanged', (_chainId) => {
            if (connectedChainId !== _chainId) {
                dispatch({ type: 'LOAD_CHAIN_CHANGED', payload: _chainId });
            }
        });
    });

    useEffect(() => {
        const getPrivateSaleDetails = async () => {
            try {
                let { data } = await axios.get('/rushsale/getPrivateSaleDetails');
                data['max'] = data?.max / Math.pow(10, 18);
                setUserSaleDetails(data);
            } catch (error) {
                console.error(error);
            }
        }
        if (walletAddress !== null) {
            getPrivateSaleDetails();
        }
    }, [walletAddress, dispatch]);

    useEffect(() => {
        const getCollectedAmount = async () => {
            try {
                let amount = await dispatch(allActions.buyActions.collectedAmount());
                amount = Number(amount) / Math.pow(10, 18);
                setCollectedAmount(amount);
            } catch (error) {
                console.error(error);
            }
        };
        getCollectedAmount();
    }, [dispatch]);

    useEffect(() => {
        const saleConfiguration = async () => {
            try {
                let { Item } = await axios.get('/rushsale/saleConfiguration');
                Item['maxContribute'] = Number(Item.maxContribute) / Math.pow(10, 18);
                let now = moment().unix();
                if (Item?.startTime > now) {
                    Item['saleStarted'] = false;
                } else {
                    if (Item?.deadline < now) {
                        Item['saleStarted'] = true;
                        Item['saleEnded'] = true;
                    } else {
                        Item['saleStarted'] = true;
                    }
                };
                setSaleConfiguration(Item);
            } catch (error) {
                console.error(error);
            }
        };
        saleConfiguration();
    }, []);

    const getTimeDifference = () => {
        let time = saleConfiguration?.saleStarted ? saleConfiguration.deadline : saleConfiguration?.startTime;
        var now = moment().format('DD/MM/YYYY HH:mm:ss');
        var ms = moment(Number(time) * 1000).diff(moment(now, 'DD/MM/YYYY HH:mm:ss'));
        let { _data } = moment.duration(ms);
        return `${_data.days}D : ${_data.hours}H : ${_data.minutes}M : ${_data.seconds}S`;
    }

    return (
        <div className={styles.box}>
            <div className={styles.header}>
                <span>
                    <img src="/assets/rushicon.svg" alt="logo" />
                    Holder’s
                    <br />
                    Exclusive Sale
                </span>
                {saleConfiguration?.saleStarted && userSaleDetails?.min && (
                    <div className={styles.tabView}>
                        <div className={activeTab === 0 ? `${styles.button} ${styles.active}` : `${styles.button}`} onClick={() => setActiveTab(0)}>Buy</div>
                        <div className={activeTab === 1 ? `${styles.button} ${styles.active}` : `${styles.button}`} onClick={() => setActiveTab(1)}>Stake</div>
                    </div>
                )}
            </div>
            {saleConfiguration?.saleStarted
                ? (
                    <DuringSale
                        walletAddress={walletAddress}
                        userSaleDetails={userSaleDetails}
                        connectWallet={connectWallet}
                        activeTab={activeTab}
                        saleConfiguration={saleConfiguration}
                        collectedAmount={collectedAmount}
                    />
                )
                : (
                    <BeforeSale
                        walletAddress={walletAddress}
                        userSaleDetails={userSaleDetails}
                        connectWallet={connectWallet}
                    />
                )}

            <div className={styles.footer}>
                {saleConfiguration?.saleEnded
                    ? (
                        <>SALE ENDED</>
                    )
                    : (
                        <>
                            {saleConfiguration?.saleStarted ? 'SALE ENDS IN' : 'SALE STARTS IN'} : <span>{getTimeDifference()}</span>
                        </>
                    )
                }
            </div>

        </div>
    )
}