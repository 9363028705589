import Web3 from 'web3';

const privateSaleContribute = () => async (dispatch, getState) => {
    const { BUY_CONTRACT_ADDRESS_ABI, BUY_CONTRACT_ADDRESS, POLYGON_INFURA_URL, walletAddress } = getState().auth;
    let currentProvider = new Web3(POLYGON_INFURA_URL);
    let contract = new currentProvider.eth.Contract(BUY_CONTRACT_ADDRESS_ABI, BUY_CONTRACT_ADDRESS).methods;
    return await contract.privateSaleContribute(walletAddress).call();
};

const collectedAmount = () => async (dispatch, getState) => {
    const { BUY_CONTRACT_ADDRESS_ABI, BUY_CONTRACT_ADDRESS, POLYGON_INFURA_URL } = getState().auth;
    let currentProvider = new Web3(POLYGON_INFURA_URL);
    let contract = new currentProvider.eth.Contract(BUY_CONTRACT_ADDRESS_ABI, BUY_CONTRACT_ADDRESS).methods;
    return await contract.collectedAmount().call();
};

const contribute = (data) => async (dispatch, getState) => {
    const { BUY_CONTRACT_ADDRESS_ABI, BUY_CONTRACT_ADDRESS, web3Provider, walletAddress } = getState().auth;
    let currentProvider = new Web3(web3Provider);
    let contract = new currentProvider.eth.Contract(BUY_CONTRACT_ADDRESS_ABI, BUY_CONTRACT_ADDRESS).methods;
    try {
        return await contract.contribute(Number(data.value.minAmount.hex), Number(data.value.maxAmount.hex), data.signature).send({ maxPriorityFeePerGas: null, maxFeePerGas: null, value: 0.000001 * Math.pow(10, 18), from: walletAddress });
    } catch (error) {
        throw error
    };
};

const buyActions = {
    privateSaleContribute,
    contribute,
    collectedAmount
};

export default buyActions;