import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import configureStore from './redux';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import NavBar from 'components/NavBar';
import LandingLeftPanel from 'components/LandingLeftPanel';
import LandingRightPanel from 'components/LandingRightPanel';
export const store = configureStore();
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <React.Suspense fallback={<></>}>
      <NavBar />
      <div className="mainSection container">
        <LandingLeftPanel />
        <LandingRightPanel />
      </div>
    </React.Suspense >
  </Provider >
);
reportWebVitals();
