import styles from './index.module.scss';
export default function LandingLeftPanel() {
    return (
        <div className={styles.leftPanel}>
            <h1>
                The Future of <br />gaming is here⚡
            </h1>
            <div className={styles.leftBanner}>
                <img src="/assets/leftBanner.svg" alt="banner" />
                <p>
                    Immerse yourself in an uncharted world of Web3 gaming, where creativity and innovation collide.
                </p>
            </div>
        </div>
    )
}