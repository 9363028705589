import EligibleView from 'components/EligibleView';
import styles from '../LandingRightPanel/index.module.scss';

export default function BeforeSale(props) {
    return (
        <div className={styles.main}>
            {props.walletAddress === null
                ? (
                    <>
                        <img src="/assets/bag.svg" alt="icon" className={styles.bagIcon} />
                        <button className={styles.connectButton} onClick={() => props.connectWallet()}>
                            Connect Wallet
                        </button>
                        <div className={styles.alertBox}>
                            <img src="/assets/wallet.svg" alt="icon" className={styles.walletIcon} />
                            Connect your wallet to check your Eligibility
                        </div>
                    </>
                )
                : (
                    <>
                        <div className={styles.connectedArea}>

                            <EligibleView props={props} />

                            <div className={styles.alertBox}>
                                {props?.userSaleDetails?.min || props?.userSaleDetails?.min
                                    ? (
                                        <>
                                            <img src="/assets/success.svg" alt="icon" className={styles.walletIcon} />
                                            You are eligible to participate in the Sale
                                        </>
                                    )
                                    : (
                                        <>
                                            <img src="/assets/error.svg" alt="icon" className={styles.walletIcon} />
                                            You do not meet the criteria to participate in the sale
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    )
}