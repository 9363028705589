import Config from 'Config';
import Amplify from 'aws-amplify';

const initialState = {
    isUserConnected: false,
    isAuthenticated: false,
    isWalletConnecting: false,
    authConnectionType: { metamask: 'custom-metamask', coinbase: 'custom-coinbase', walletconnect: 'custom-walletconnect', sequence: 'sequence', injected: 'injected', brave: 'custom-brave' },
    walletAddress: null,
    web3Provider: null,
    connectedAccounts: [],
    connectedChainId: null,
    BUY_CONTRACT_ADDRESS: Config.BUY_CONTRACT_ADDRESS,
    BUY_CONTRACT_ADDRESS_ABI: Config.BUY_CONTRACT_ADDRESS_ABI,
    ETH_PRICE: Config.ETH_PRICE,
    RUSH_PRICE_USD: Config.RUSH_PRICE_USD,
    ETH_INFURA_URL: Config.ETH_INFURA_URL,
    POLYGON_INFURA_URL: Config.POLYGON_INFURA_URL
};

export default function authReducer(state = initialState, action) {

    switch (action.type) {
        case 'isWalletConnecting':
            return {
                ...state,
                isWalletConnecting: action.payload,
            };
        case 'isUserConnected':
            return {
                ...state,
                isUserConnected: action.payload,
            };
        case 'LOAD_USER_DETAILS':
            localStorage.setItem('isUserLoggedIn', action.payload.status);
            return {
                ...state,
                isAuthenticated: action.payload.status,
            };
        case 'USER_SIGN_OUT':
            return {
                ...state,
                isAuthenticated: false
            };
        case 'LOAD_WALLET_ADDRESS':
            return {
                ...state,
                walletAddress: action.payload
            };
        case 'LOAD_WEB3_PROVIDER':
            return {
                ...state,
                web3Provider: action.payload,
            };
        case 'LOAD_CONNECTED_ACCOUNT':
            return {
                ...state,
                connectedAccounts: action.payload,
            };
        case 'LOAD_CHAIN_CHANGED':
            return {
                ...state,
                connectedChainId: action.payload,
            };
        default:
            return state;
    }
}
setupCognito();
function setupCognito() {
    const cognitoPoolId = Config.Amplify_Details.cognitoPoolId;
    const cognitoWebClientId = Config.Amplify_Details.cognitoWebClientId;
    const region = Config.Amplify_Details.region;

    if (!cognitoPoolId || !cognitoWebClientId) {
        return;
    }

    Amplify.configure({
        Auth: {
            region,
            userPoolId: cognitoPoolId,
            userPoolWebClientId: cognitoWebClientId,
            authenticationFlowType:
                Config.Amplify_Details.authenticationFlowType,
        },
    });
}