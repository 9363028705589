import EligibleView from 'components/EligibleView';
import styles from '../LandingRightPanel/index.module.scss';
import BuyNowView from 'components/BuyNowView';
import StakeView from 'components/StakeView';

export default function DuringSale(props) {
    return (
        <>
            <div className={styles.main}>
                {props.walletAddress === null
                    ? (
                        <>
                            <div className='sliderWraper'>
                                <input disabled type="range" className={styles.rangeSlider} id="range3" value={props?.collectedAmount || 0} max={props?.saleConfiguration?.maxContribute} style={{ background: `linear-gradient(to right, #31c859 ${props?.collectedAmount}%, #161616 ${props?.collectedAmount}%)` }} />
                                <div className='sliderInfo'>
                                    <span>{props?.collectedAmount.toString().toLocaleString('en_US')} MATIC</span>
                                    <span>{props?.saleConfiguration?.maxContribute.toLocaleString('en-US')} MATIC</span>
                                </div>
                            </div>
                            <button className={styles.connectButton} onClick={() => props.connectWallet()}>
                                <img src="/assets/white_wallet.svg" alt="icon" />Connect Wallet
                            </button>
                            <div className={styles.alertBox}>
                                <img src="/assets/wallet.svg" alt="icon" className={styles.walletIcon} />
                                Connect your wallet to check your Eligibility
                            </div>
                        </>
                    )
                    : (
                        <>
                            {props.activeTab === 0
                                ? (
                                    <div className={styles.connectedArea}>

                                        <div className='sliderWraper'>
                                            <input disabled type="range" className={styles.rangeSlider} id="range3" value={props?.collectedAmount || 0} max={props?.saleConfiguration?.maxContribute} style={{ background: `linear-gradient(to right, #31c859 ${props?.collectedAmount}%, #161616 ${props?.collectedAmount}%)` }} />
                                            <div className='sliderInfo'>
                                                <span>{props?.collectedAmount.toString().toLocaleString('en_US')} MATIC</span>
                                                <span>{props?.saleConfiguration?.maxContribute.toLocaleString('en-US')} MATIC</span>
                                            </div>
                                        </div>

                                        {props?.userSaleDetails?.min
                                            ? (<BuyNowView props={props} />)
                                            : (<EligibleView props={props} />)
                                        }

                                        <div className={styles.alertBox}>
                                            {props?.userSaleDetails?.min || props?.userSaleDetails?.min
                                                ? (
                                                    <>
                                                        <img src="/assets/success.svg" alt="icon" className={styles.walletIcon} />
                                                        <span>
                                                            You are eligible to participate. Enter the desired amount you want to buy for and Click
                                                            <span style={{ color: '#ffffff', marginLeft: '2px' }}>Buy Now</span>
                                                        </span>
                                                    </>
                                                )
                                                : (
                                                    <>
                                                        <img src="/assets/error.svg" alt="icon" className={styles.walletIcon} />
                                                        <span>
                                                            You aren’t eligible to participate in the Sale, make sure you meet the criteria. For more info, <a href="https://www.google.com" target="_blank" rel="noreferrer">Click here.</a>
                                                        </span>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                                : (
                                    <StakeView props={props} />
                                )
                            }
                        </>
                    )
                }
            </div >
        </>
    )
}