import styles from './index.module.scss';
export default function StakeView({ props }) {
    return (
        <div className={styles.stakeWraper}>
            <div className={styles.info}>
                <div className={styles.box}>
                    <span>Left to Stake</span>
                    <h1>7,500</h1>
                </div>
                <div className={styles.box}>
                    <span>Staked Amount</span>
                    <h1>10,000</h1>
                </div>
                <div className={styles.box}>
                    <span>Bonus Rush</span>
                    <h1>300,000</h1>
                </div>
            </div>
            <div className={styles.info2}>
                <div className={styles.box}>
                    <h1>7,500</h1>
                    <span>You’ll Stake</span>
                </div>
                <div className={styles.box}>
                    <h1>500</h1>
                    <span>Your Bonus</span>
                </div>
            </div>
            <div className='sliderWraper'>
                <input
                    type='range'
                    id="stakeSlider"
                    style={{ 'background': 'linear-gradient(to right, #3B5AFF 60%, #161616 60%)' }}
                    value={60}
                />
                <div className='sliderInfo'>
                    <span>0%</span>
                    <span>100%</span>
                </div>
            </div>

            <button className={styles.connectButton} disabled>
                Pre-Stake Now
            </button>
            <div className={styles.alertBox}>
                <img src="/assets/stakeSuccess.svg" alt="icon" className={styles.walletIcon} />
                <span>
                    Pre-stake your Rush Tokens to get an additional 10% Bonus
                </span>
            </div>
        </div>
    )
}