import styles from './index.module.scss';
export default function NavBar() {
    return (
        <nav>
            <div className="container">
                <div className={styles.navBar}>
                    <img src="/assets/logo.svg" alt="logo" />
                    <a href="https://app.bullieverse.com" target="_blank" rel="noopener noreferrer">Play Now</a>
                </div>
            </div>
        </nav>
    )
}